@font-face {
    font-family: "roboto-bold";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
   }
  
   @font-face {
    font-family: "roboto-light";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
    
   }
   @font-face {
    font-family: "roboto-regular";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
    
   }

.diseno{
    color: white;
    background-color: black;
    height: 100vh;
    width: 100vw;
}
.contdise{
    padding-top: 5vh;
    padding-left: 5vw;
    width: 100vw;
    height: 100vh;
}
.spansub{
    font-family: "roboto-light";
    padding-top: 5vh;
    padding-right: 3vw;
    height: 200px;
    width: 300px;
}
.titulogrand{
    line-height: 0.8;
    font-family: "roboto-bold";
    font-size: 8vh;
}
.parradise{
    font-family: "roboto-light";
    line-height: 1;
    padding-top: 4svh;
    color: #808080;
    max-width: 90%;
    text-align: justify;
}
.titulod{
    display: flex;
    justify-content: left;
    align-items: center;
    height: 20vh;
    width: 90vw;
    overflow-x: hidden;

}
.navbardiseno{
    font-size: 2vh;
    transform: rotate(180deg);
    writing-mode:vertical-rl;
    color: white;
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0);
}

.titulo3d{
    display: flex;
    justify-content: center;
    height: 10vh;

}
.modelo3d{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
}
.control3d{
    width: 100%;
    display:flex;
    justify-content: center;
    height: 20vh;
}
.barranegra{
    display: flex;
    width: 300px;
    height: 2px;
    background-color: #808080;
}

.barraroja{
    display: flex;
    min-width: 200px;
    height: 2px;
    background-color: red;
}
.btnant{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: red;
}
.contr3d{

    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
}
.ctr3d{
    display: flex;
    justify-content: center;
}
.pan3d{
    padding-right: 10vw;
}
.imagen3d{
    width: 40vh;
}
.infod{
    height: 60vh;
}
.centrado{
    display: flex;
    justify-content: center;

}