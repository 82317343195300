@font-face {
    font-family: "roboto-bold";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
   }
  
   @font-face {
    font-family: "roboto-light";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
    
   }
   @font-face {
    font-family: "roboto-regular";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
    
   }

.servicios{
    height: 100vh;
    background-color: red;
    color: black;
}
.titulogranserv{
    display: flex;
    line-height: 1.2;
    font-family: "roboto-bold";
    font-size: 8vh;
    color: white;
}
.parrafserv{
    max-width: 500px;
    font-family: "roboto-light";
    color: white;
}
.cuerposerv{
    padding-top: 5vh;
    height: 90vh;
    width: 90vw;
}
.contserv{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    height: 10vh;

}
.antitulo{
    height: 5vh;
    display: flex;
    align-items: start;
    line-height: .5;
    padding-bottom: 0;
}
.circuloserv{
    background-image: url('../imagenes/circulo.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: 120vh;
    background-position-x: -25vh;
}
.contcirc{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cara{
    width: 20vw;
}
.subtblanco{
    color: white;
    font-family: "roboto-bold";
}
.lineahori{
    display: flex;
    width: 60%;
    height: 1px;
    background-color:#f20000;
}
.numerocv{
    font-family: "roboto-bold";
    color: #7C0000;
}
.formacion{
    font-family: "roboto-light";
    color: #450000d1;
}
.cuadroicono{
    display: flex;
    width: 60px;
    height: 60px;
    background-color: #FF1C1C;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.cuadroicono2{
    display: flex;
    width: 60px;
    height: 60px;
    background-color: #F90000;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.franjanegra{
    padding-left: 3vw;
    margin-right: 3vw;
    color: white;
    display: flex;
    width: 300px;
    background-color: black;
    font-family: "roboto-bold";
}
.btnant1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color:#D40000;
}
.barraroja1{
    display: flex;
    min-width: 200px;
    height: 2px;
    background-color:#BB0000;
}
.barranegra1{
    display: flex;
    width: 300px;
    height: 2px;
    background-color: #F90000;
}
.pruebaser{
    display: flex;
    justify-content: left;

}
.iconoscua{
    display: flex;
}
.menum2{

    display: flex;
    justify-content: left;
}
.iconquien{
    height: 100%;
}