@font-face {
    font-family: "roboto-bold";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
   }
  
   @font-face {
    font-family: "roboto-light";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
    
   }
   @font-face {
    font-family: "roboto-regular";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
    
   }
.relleno2{

    background-color: black;
    width: 100vw;
    height: 50vh;
    color: white;
}
.mitadfutu{
    padding-left: 5vw;
    padding-top: 5vh;
}
.titulogrand2{
    line-height: .9;
    font-family: "roboto-bold";
    font-size: 24px;}
.parraforelleno2{
        font-family: "roboto-light";
    }
.mitadfuturo{
    background-image: url('../imagenes/fondofuturo.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40vh;
}