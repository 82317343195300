@font-face {
    font-family: "roboto-bold";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
   }
  
   @font-face {
    font-family: "roboto-light";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
    
   }
   @font-face {
    font-family: "roboto-regular";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
    
   }
.relleno{
    padding-top: 5vh;
    display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
    height: 50vh;
    background-color: black;
    color: white;
    text-align: center;
}
.titulogrande{


    font-family: "roboto-bold";
    color: white;
    font-size: 6vh;

}
.parraforelleno{
    width: 20vw;
    font-family: "roboto-light";
    padding-top: 6vh;
    padding-bottom: 6vh;
}
.rojo{
    color: red;
}
.parrafrelle1{
    padding-top: 5vh;
    display: flex;
    justify-content: left;
    align-items: center;
    max-width: 300px;
}