@tailwind base;
@tailwind components;
@tailwind utilities;

.App {

}

@font-face {
  font-family: "roboto-bold";
  src: local("roboto"),
   url("./fonts/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "roboto-light";
  src: local("roboto"),
   url("./fonts/roboto/Roboto-Light.ttf") format("truetype");
  
 }


