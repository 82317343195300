.estructura{
    background-color: red;
    width: 100vw;
    height: 100vh;
}
.navbar{
    background-color: blue;
    display: flex;
    align-items: center;
    justify-content: center;
}
.titulo{
    background-color: brown;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    height: 20vh;
  }
  .info{
    height: 60vh;
    background-color: rgb(30, 210, 126);
  }