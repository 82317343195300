@font-face {
    font-family: "roboto-bold";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
   }
  
   @font-face {
    font-family: "roboto-light";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
    
   }
   @font-face {
    font-family: "roboto-regular";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
    
   }


.contacto{
    width: 100vw;
    height: 100vh;
    background-color: red;
    color: white;
}
.mitadcontacto{
    padding-left: 5vw;
    padding-top: 5vh;
}
.circulorojo{
    font-family: "roboto-bold";
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #C80000;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cuadrocont{
    
    padding-top: 3vh;

}
.parracont{
    display: flex;
    padding-left: 2vw;
    width: 400px;
}

.prueba{
    display: flex;
    justify-content: center;
    max-width: 300px;
}
.footer{
    height: 10vh;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
}
.cuerpocontacto{
    height: 90vh;
}
.formcont{
    display: inline-flex;
    padding-top: 5vh;
}
.fondolinea{

    height: 10vh;
    display: flex;
    justify-content: center;
    width: 100%;
    background-image: url('../imagenes/lineacont.svg');
    background-repeat: no-repeat;
    background-position: center;
 
}
.prueba1{
    display: flex;
    justify-content: center;
    align-items: end;
    border: #C80000;
}
.prueba2{
    display: flex;
    align-items: start;
    justify-content: center;
    border: #C80000;
}
.mitadcontact{
    margin-left: 5vw;
    padding-top: 5vh;

}
.pruet{
    width: 40vw;
}
.inputcont{
    padding-top: 4vh;
}
.btncont{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #C80000;
    border-radius: 10px;
    width:200px;
}
.titulocont{
    padding-top: 5vh;
    margin-left: 5vw;
    height: 10vh;
}