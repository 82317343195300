@font-face {
    font-family: "roboto-bold";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
   }
  
   @font-face {
    font-family: "roboto-light";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
    
   }
   @font-face {
    font-family: "roboto-regular";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
    
   }

.inicio{
    background-color: red;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.bar{
    height: 16vh;
    background-color: red;
}
.numeros{
    height: 20vh;
    width: 90vw;
    color: aliceblue;
    background-image: url('../imagenes/fecha.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 45vh;




  
    
}

.numchi{
    font-size: 20vw;
    color:#760000;
}
.numgra{
    font-size: 40vw;
}
.iniciologo{
height: 10vh;
}
.navbar{
font-size: 2vh;
transform: rotate(180deg);
writing-mode:vertical-rl;
color: white;
display: flex;
height: 100vh;
align-items: center;
justify-content: centerd;
background-color: red;

}
.controles{
    background-color: aqua;

    display: flex;
    align-items: center;
    justify-content: center;
}
.colorazul{
    color: blue;
}
.divnum1{
    width: 10vw;
    color: black;
    overflow: hidden;
    display: flex;
   justify-content: center;
}
.divnum2{
    width: 50vw;
    display: flex;
    color: blue;
    overflow: hidden;
    justify-content: center;
}
.spanactive{
    font-size:20vh;
}
.cuadrrojo{
    width: 250px;
    height: 12vh;

}
.uno{

    font-size: 5vw;
    color: #7C0000;

    font-family: "roboto-bold";

}
.dos{

  
    font-size: 10vw;
    color: white;
    font-family: "roboto-bold";

}
.tres{

    font-family: "roboto-bold";
    font-size: 5vw;
    color: #7C0000;
  
}
.contenidocv{

    width: 100vw;
 height: 45vh;


}
.contenidocv2{
opacity: 0;
width: 0;

}
.contenidocv3{
opacity: 0;
width: 0;

}
.flecha{
    width: 20px;
  }
  .bot2{
    width: 80px;
    height: 30px;
    background-color: #e5e5e5;
    border-radius: 0px 50px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bot1{
    width: 80px;
    height: 30px;
    background-color: #CD0000;
    border-radius: 0px 0px 0px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.contenidoc{
    display: flex;
    overflow: hidden;
}
.titulocv{
    line-height: 1;
    color: white;
    font-size: 5vh;
    font-family: "roboto-bold"

}
.subtitulocv{
    font-family: "roboto-bold";
    color: #760000;
}
.parrafocv{
    font-family: "roboto-regular";
    color: #bd0000;
    width:90%;

}
.gerpue{

    font-family: "roboto-bold";
    color: white;
    font-size: 30px;
    line-height: 1.5;
}
.mitadcv{
    background-image: url('../imagenes/foto.png');
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 0;
    min-width: 40vw;
    min-height: 64vh;
}
.a{
   display: flex;
   justify-content: center;
   align-items: end;
}
.textshow{

    font-family: "roboto-bold";
    font-size: 30px;
    color: red;
    text-shadow:1px 1px 2px rgb(194, 0, 0), 0 0 1em rgb(194, 0, 0), 0 0 0.2em rgb(179, 0, 0);
}
.subti{
    display: flex;
    justify-content: center;
    font-family: "roboto-bold";
    line-height: 1;
    color: rgb(206, 0, 0);
}
.menum{

    display: flex;
    justify-content: center;
    padding-right: 2vw;
    padding-bottom: 2vw;
    color: #A90000;
}
.manumact{
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    font-family: "roboto-bold";
    padding-left: 1vw;
    padding-right: 1vw;
    font-size: 20px;
    background-color: #f30000;
    width: 100px;
    height: 100px;
    border-radius: 100%;
}
.numenudiv{
    position: relative;

}
.linea{
    height: 30vh;
    align-items: center;
    padding-bottom: 2vh;
    padding-top: 2vh;

}
.cuadrito{
    display: inline-block;
    width: 300px;
    height: 100%;
    background-color: #f40000;
    background-image: url('../imagenes/lineaspan.svg');
    background-repeat: no-repeat;
    background-position: left;
    
}
.tituinicio{
    display: flex;
    align-items: center;
    justify-content: center;
}
.circulobtn{
    margin-left: 5px;
    margin-right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background-color: #DE0000;
}
.circulobtnact{
    margin-left: 5px;
    margin-right: 5px;
    width: 60px;
    height: 20px;
    border-radius: 25px;
    background-color: #7C0000;
}