@font-face {
    font-family: "roboto-bold";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
   }
  
   @font-face {
    font-family: "roboto-light";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Light.ttf") format("truetype");
    
   }
   @font-face {
    font-family: "roboto-regular";
    src: local("roboto"),
     url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
    
   }






.bienvenida{
    background-color: black;
    color: white;
    height: 100vh;
    width: 100vw;
}
.barbienvenida{
    height: 10vh;

}
.cuerpobienvenida{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
}
.fotbienvenida{
    height: 20vh;

}
.logo{

    width: 100vw;
}
.rojo{
    font-family: "roboto-bold";
    color: red;
}
.spanbienve{
 
    padding-left: 1vw;

    width: 100%;
    height: 50%;
    display: inline-block;
}
.parrafbienve{
    margin-left: 5vw;
    color: white;
    font-size: 0.7rem;
    max-width: 300px;
}
.pruebg{
    margin-left: 5vw;
}